import React, {useEffect, useState} from "react"
import { Link, navigate } from "gatsby"
import {Container,Navbar,Nav, Row, Col, Form, Button} from 'react-bootstrap';
import NavLink from  "../../components/NavLink/Custom_Presentation"; 
import Header from  "../../components/Common/Header"; 
import SEO from "../../components/seo"
import Signature from "../../components/modules/signature"

import {config, paymentFees} from '../../components/Common/constant';

import HTMLReactParser from 'html-react-parser';
import { GET_ARTICLE, SELLER_DETAILS_EMAIL } from "../../queries/common_use_query";

import {uniqueID} from "../../components/utils/common";

import { useContainerContext } from "../../store/ContainerContext";

import ContractForm from "../../components/modules/contract_form"

const ContractPage = () => {
    const context = useContainerContext();
    const { property_data } = context.state;
    

    const [ article_data, setArticleData ] = useState('');
    const [fee_list,setFeeList] = useState([]);

    const { loading, error, data } = GET_ARTICLE('contract-form');
    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);


    return <>
    <SEO title={article_data && article_data.Meta_Title} description={article_data && article_data.Meta_Description} />

    <div className="pre-wrapper">
      
      <Header />

      <div className="section-wrapper custom_present_inner pt-0">
        <ContractForm property_data={property_data} />
      </div>

      <NavLink tab_name="Contract Form" prevLink={config.custom_fees} nextLink={''} />

    </div>
    </>
}

export default ContractPage
